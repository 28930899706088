<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title v-if="isLoading">Star Profil</ion-title>
        <ion-title v-if="!isLoading && !notAuthenticated">{{ star.username }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <div class="container" v-if="isLoading">
      <ion-spinner name="dots"></ion-spinner>
    </div>

    <ion-content v-if="!isLoading && !notAuthenticated">
      <ion-card>
        <img src="http://images:8888/loco_test.jpeg" alt="Star">
        <ion-card-content>
          <ion-card-subtitle>Sellebrity Profil von</ion-card-subtitle>
          <ion-card-title>{{ star.username }}</ion-card-title>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col size="6">
              <ion-card-subtitle>Aktive<br> Inserate</ion-card-subtitle>
              <ion-card-title>
                <ion-skeleton-text style="width: 25px;" v-if="isLoadingInseratAmounts"></ion-skeleton-text>
                <span v-if="!isLoadingInseratAmounts">{{ inseratsActive }}</span>
              </ion-card-title>
            </ion-col>
            <ion-col size="6">
              <ion-card-subtitle>Verkaufte<br> Inserate</ion-card-subtitle>
              <ion-card-title>
                <ion-skeleton-text style="width: 25px;" v-if="isLoadingInseratAmounts"></ion-skeleton-text>
                <span v-if="!isLoadingInseratAmounts">{{ inseratsSold }}</span>
              </ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card v-if="inseratsActive > 0">
        <ion-card-content>
          <ion-button style="width: 100%;" @click="toggleInserats()"><span v-if="!showInserats">Aktive Inserate anschauen</span><span v-if="showInserats">Inserate ausblenden</span></ion-button>
        </ion-card-content>
      </ion-card>

      <div v-if="showInserats && !isLoadingInserats">
        <inseratCard v-for="(inserat, key) in inserats" :key="key" :id="inserat.id" :tags="true"></inseratCard>
        <ion-infinite-scroll
            @ionInfinite="loadMoreInserats($event)"
            threshold="100px"
            id="infinite-scroll"
        >
          <ion-infinite-scroll-content
              loading-spinner="dots"
              loading-text="Weitere Inserate werden geladen">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>


    </ion-content>
    <ion-content v-if="notAuthenticated">

      <div class="container sideMargins">
        <ion-icon :ios="authIconIos" :md="authIconMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
        <strong class="capitalize">Du musst eingeloggt sein um dieses Profil aufzurufen.</strong>
        <p>Du kannst einen Account im Login Bereich erstellen.</p><br>
        <ion-button size="large" class="topMargins sideMargins" href="/all" expand="block" color="light">Fertig</ion-button>
      </div>

    </ion-content>

  </ion-page>
</template>

<script lang="ts">

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  toastController,
  IonSpinner,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonInfiniteScroll, IonInfiniteScrollContent,
    IonIcon,
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratCard from '../components/inseratCard.vue';
import { accessibilityOutline, accessibilitySharp } from 'ionicons/icons';


export default defineComponent({
name: "showStar",
  data () {
    return {
      star: null,
      isLoading: false,
      isLoadingInseratAmounts: true,
      inseratsSold: 0,
      inseratsActive: 0,
      page: 1,
      isLoadingInserats: false,
      showInserats: false,
      inserats: null,
      totalInserats: 0,
      notAuthenticated: false,
      authIconIos: accessibilityOutline,
      authIconMd: accessibilitySharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonButton,
    IonSpinner, IonRow, IonCol, IonSkeletonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    inseratCard,
    IonIcon
  },
  created() {

    // todo: check if user is authenticated if not show error
    if (!localStorage.userId || !localStorage.userToken || !localStorage.refreshToken) {
        this.notAuthenticated = true;
    } else {
      // get star data
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/users/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.star = response.data;

            // load inserat amount
            const formData = new FormData();
            formData.append("star", this.star.id);
            axios.post(process.env.VUE_APP_API_URL + '/star-inserats-count', formData).then(result => {
              this.inseratsActive = result.data.amount.active;
              this.inseratsSold = result.data.amount.sold;
              this.isLoadingInseratAmounts = false;
            });

          })
          .finally(() => {
            this.isLoading = false;
          });
    }

  },
  methods: {
    loadMoreInserats (ev) {
      setTimeout(() => {

        // check if there are more than 30 items
        if (this.totalInserats > 30){
          // load inserats from next page
          this.page += 1;

          axios.get(process.env.VUE_APP_API_URL + '/inserats?page='+this.page+'&createdBy='+ this.star.id)
              .then(response => {

                response.data["hydra:member"].forEach(element => {
                  this.inserats.push(element);
                });

              }).finally(() => {
            ev.target.complete();
            ev.target.disabled = true;
          });

        } else {
          this.openToast('Keine weiteren Inserate gefunden.', 2000);
          ev.target.complete();
          ev.target.disabled = true;
        }

      }, 500);
    },
    toggleInserats() {

      if (this.showInserats){
          this.showInserats = false;
      } else {
          this.showInserats = true;

          // if inserats are not loaded, load them.
          if (this.inserats === null){
              console.log("No Inserats found");
              // load inserats
              this.isLoadingInserats = true;

              axios.get(process.env.VUE_APP_API_URL + '/inserats?createdBy=' + this.star.id).then(response => {
                  this.inserats = response.data['hydra:member'];
                  this.totalInserats = response.data['hydra:totalItems']
                  this.isLoadingInserats = false;
              });

          }

      }

    },
    async openToast(message: string, duration: number, color: string) {
      if (!color){ color = "primary";}
      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
  }
});
</script>

<style scoped>

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>