

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  toastController,
  IonSpinner,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonInfiniteScroll, IonInfiniteScrollContent,
    IonIcon,
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratCard from '../components/inseratCard.vue';
import { accessibilityOutline, accessibilitySharp } from 'ionicons/icons';


export default defineComponent({
name: "showStar",
  data () {
    return {
      star: null,
      isLoading: false,
      isLoadingInseratAmounts: true,
      inseratsSold: 0,
      inseratsActive: 0,
      page: 1,
      isLoadingInserats: false,
      showInserats: false,
      inserats: null,
      totalInserats: 0,
      notAuthenticated: false,
      authIconIos: accessibilityOutline,
      authIconMd: accessibilitySharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonButton,
    IonSpinner, IonRow, IonCol, IonSkeletonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    inseratCard,
    IonIcon
  },
  created() {

    // todo: check if user is authenticated if not show error
    if (!localStorage.userId || !localStorage.userToken || !localStorage.refreshToken) {
        this.notAuthenticated = true;
    } else {
      // get star data
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/users/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.star = response.data;

            // load inserat amount
            const formData = new FormData();
            formData.append("star", this.star.id);
            axios.post(process.env.VUE_APP_API_URL + '/star-inserats-count', formData).then(result => {
              this.inseratsActive = result.data.amount.active;
              this.inseratsSold = result.data.amount.sold;
              this.isLoadingInseratAmounts = false;
            });

          })
          .finally(() => {
            this.isLoading = false;
          });
    }

  },
  methods: {
    loadMoreInserats (ev) {
      setTimeout(() => {

        // check if there are more than 30 items
        if (this.totalInserats > 30){
          // load inserats from next page
          this.page += 1;

          axios.get(process.env.VUE_APP_API_URL + '/inserats?page='+this.page+'&createdBy='+ this.star.id)
              .then(response => {

                response.data["hydra:member"].forEach(element => {
                  this.inserats.push(element);
                });

              }).finally(() => {
            ev.target.complete();
            ev.target.disabled = true;
          });

        } else {
          this.openToast('Keine weiteren Inserate gefunden.', 2000);
          ev.target.complete();
          ev.target.disabled = true;
        }

      }, 500);
    },
    toggleInserats() {

      if (this.showInserats){
          this.showInserats = false;
      } else {
          this.showInserats = true;

          // if inserats are not loaded, load them.
          if (this.inserats === null){
              console.log("No Inserats found");
              // load inserats
              this.isLoadingInserats = true;

              axios.get(process.env.VUE_APP_API_URL + '/inserats?createdBy=' + this.star.id).then(response => {
                  this.inserats = response.data['hydra:member'];
                  this.totalInserats = response.data['hydra:totalItems']
                  this.isLoadingInserats = false;
              });

          }

      }

    },
    async openToast(message: string, duration: number, color: string) {
      if (!color){ color = "primary";}
      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
  }
});
